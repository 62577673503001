import { combineReducers } from "redux";
import userSlice from "./slices/userSlice.ts";
import authSlice from "./slices/authSlice.ts";
import colaboradoresSlice from "./slices/colaboradoresSlice.ts";
import listaNegraSlice from "./slices/listaNegraSlice.ts";
import catalogSlice from "./slices/catalogSlice.ts";
import solicitudesSlice from "./slices/solicitudesSlice.ts";
import puestosSlice from "./slices/puestosSlice.ts";
import sucursalesSlice from "./slices/sucursalesSlice.ts";
import vacantesSlice from "./slices/vacantesSlice.ts";
import tallasSlice from "./slices/tallasSlice.ts";
import periodicidadSlice from "./slices/periodicidadSlice.ts";
import turnosSlice from "./slices/turnosSlice.ts";
import branchTemplateSlice from "./slices/branchTemplateSlice.ts";
import traspasosSlice from "./slices/traspasosSlice.ts";
import colaboradoresBranchSlice from "./slices/colaboradoresBranchSlice.ts";
import entradasSlice from "./slices/entradasSlice.ts";
import chequeoSlice from "./slices/chequeoSlice.ts";
import inasistenciasSlice from "./slices/inasistenciasSlice.ts";
import notificationsUserSlice from "./slices/notificationsUserSlice.ts";
import reportSlice from "./slices/ReportsSlice.ts";
import badgesSlice from "./slices/badgesSlice.ts";
import incidentSlice from "./slices/incidentSlice.ts";
import scheduleSlice from "./slices/scheduleSlice.ts";

const rootReducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  vacantes: vacantesSlice,
  colaboradores: colaboradoresSlice,
  listaNegra: listaNegraSlice,
  catalog: catalogSlice,
  solicitud: solicitudesSlice,
  puestos: puestosSlice,
  sucursales: sucursalesSlice,
  tallas: tallasSlice,
  periodicidad: periodicidadSlice,
  turnos: turnosSlice,
  branchTemplates: branchTemplateSlice,
  traspasos: traspasosSlice,
  colaboradoresBranch: colaboradoresBranchSlice,
  entradas: entradasSlice,
  chequeo: chequeoSlice,
  inasistencias: inasistenciasSlice,
  notificationUser: notificationsUserSlice,
  report: reportSlice,
  badges: badgesSlice,
  incident: incidentSlice,
  schedule: scheduleSlice,
});

export default rootReducer;
