import React from "react";
import {
  createFreeDay,
  fetchFreeDaysByDateRangeAndPartnerId,
} from "../../../../services/freeDay.service.ts";
import { weekDays, weeks } from "../../../../utils/constanst";
import { useDispatch } from "react-redux";
import { updateOrAddFreeDay } from "../../../../store/slices/scheduleSlice.ts";

const ModalFreeDays = ({
  partnerId,
  setShowModal,
  weekSelected,
}: {
  partnerId: number;
  setShowModal: (_: boolean) => void;
  weekSelected: number;
}) => {
  const dispatch = useDispatch();
  const [selectedDays, setSelectedDays] = React.useState<number[]>([]);
  const [loading, setLoading] = React.useState(true);

  const fechtFreeDay = async () => {
    try {
      const freeDays = await fetchFreeDaysByDateRangeAndPartnerId(
        weeks[weekSelected]?.value,
        partnerId
      );
      if (freeDays?.days && Array.isArray(freeDays.days)) {
        const selectedDays = freeDays.days.map((day) => {
          const index = weekDays.findIndex((d) => d.name === day);

          if (index === -1) {
            throw new Error(`Día no válido: ${day}`);
          }

          return index;
        });

        setSelectedDays(selectedDays);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const saveFreeDays = async () => {
    setLoading(true);
    try {
      const days = selectedDays.map((day) => weekDays[day].id);

      const object = {
        partnerId: partnerId,
        days: weekDays
          .filter((day) => days.includes(day.id))
          .map((d) => d.name),
        dateRange: weeks.find((w) => w.id === weekSelected)?.value,
      };

      await createFreeDay(object);

      dispatch(
        updateOrAddFreeDay({
          id: partnerId,
          freeDays: days,
        })
      );

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  React.useEffect(() => {
    fechtFreeDay();
  }, [partnerId]);

  return (
    <React.Fragment>
      <div className="d-flex w-100 my-3 col-md-12">
        <label className={`form-label col-form-label col-md-3 text-end pe-4`}>
          Días *
        </label>
        <div className="col-9 d-flex flex-row">
          {weekDays.map((day, index) => (
            <button
              key={index}
              type="button"
              onClick={() => {
                const isSelected = selectedDays.includes(index);
                setSelectedDays(
                  isSelected
                    ? selectedDays.filter((dayIndex) => dayIndex !== index)
                    : [...selectedDays, index]
                );
              }}
              className={`btn ${
                selectedDays.includes(index)
                  ? "btn-primary"
                  : "btn-outline-secondary"
              } rounded-pill mx-1`}
              title={day.name}
              style={{
                width: "30px",
                height: "30px",
                textTransform: "capitalize",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {day.name.slice(0, 1)}
            </button>
          ))}
        </div>
      </div>
      <div className="d-flex flex-row mt-5">
        <button
          className="btn btn-secondary ms-auto me-2"
          onClick={() => setShowModal(false)}
        >
          Cancelar
        </button>
        <button
          className="btn btn-primary"
          onClick={() => saveFreeDays()}
          disabled={loading}
        >
          Guardar
        </button>
      </div>
    </React.Fragment>
  );
};

export default ModalFreeDays;
