import React, { useEffect } from "react";
import WorkerRow from "./componentes/WorkerRow.tsx";
import GridLayout from "react-grid-layout";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { Tooltip, Typography } from "@mui/material";
import { readPartnersByBranch } from "../../../services/partner.service.ts";
import {
  generateGradientBackground,
  generateTimeIntervals,
  generateTimeSlots,
  getBackgroundColor,
  getDateByDayOfWeek,
  isOverlapping,
  shifts,
  weekDays,
  weeks,
} from "../../../utils/constanst.js";
import { findAssistanceByDate } from "../../../services/assistanceCheck.service.ts";
import { notification } from "../../../utils/Notifications.tsx";
import { toCamelCase } from "../../../hooks/toCameCase.tsx";
import { findPartnerSchedule } from "../../../services/branchSchedule.service.ts";
import { mergePositions } from "../../../utils/index.ts";
import ScheduleCompact from "./ScheduleCompact.tsx";
import { defaultFilters } from "../../../models/ColorSchedule.ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/index.ts";
import { resetScheduleState, setFetchFreeDays } from "../../../store/slices/scheduleSlice.ts";

function SheduleComponent({
  hours,
  existingsSchedules,
  formik,
  positionsArray,
  setPositionsArray,
  selectedWeek,
  selectedDay = 0,
  turno = 0,
}) {
  const { freeDays } = useSelector((state: RootState) => state.schedule);
  const dispatch = useDispatch();
  const branchId = formik.values.branchId;
  const [layout, setLayout] = React.useState<any[]>([]);
  const [workers, setWorkers] = React.useState<any[]>([]);
  const [lastItem, setLastItem] = React.useState<any>(null);
  const [update, setUpdate] = React.useState(false);

  const [sortType, setSortType] = React.useState("Asc");
  const [sortBy, setSortBy] = React.useState("name");

  const [hoursRange, setHoursRange] = React.useState<any[]>([]);
  const [compactFilters, setCompactFilters] = React.useState({
    resumen: true,
    realTime: false,
    assistence: false,
    late: false,
    withoutAssistence: false,
    toArrive: false,
  });

  const handleSortPositions = (type) => {
    if (type === "name") {
      setSortBy("name");
      if (sortType === "Asc") {
        setPositionsArray(
          positionsArray.sort((a, b) =>
            a.positionName.localeCompare(b.positionName)
          )
        );
        setSortType("Desc");
      } else {
        setPositionsArray(
          positionsArray.sort((a, b) =>
            b.positionName.localeCompare(a.positionName)
          )
        );
        setSortType("Asc");
      }
    } else {
      setSortBy("shift");
      if (sortType === "Asc") {
        const shiftOrder = ["Matutino", "Intermedio", "Vespertino"];
        const sorted = positionsArray.sort((a, b) => {
          return shiftOrder.indexOf(a.shift) - shiftOrder.indexOf(b.shift);
        });

        setPositionsArray(sorted);
        setSortType("Desc");
      } else {
        const shiftOrder = ["Vespertino", "Intermedio", "Matutino"];
        const sorted = positionsArray.sort((a, b) => {
          return shiftOrder.indexOf(a.shift) - shiftOrder.indexOf(b.shift);
        });

        setPositionsArray(sorted);
        setSortType("Asc");
      }
    }

    const newSortedLayout = layout.map((item) => {
      return {
        ...item,
        y: positionsArray.findIndex(
          (position) =>
            position.positionName === item.position &&
            position.shift === item.shift
        ),
      };
    });
    setLayout(newSortedLayout);

    setUpdate(!update);
  };

  React.useEffect(() => {
    if (existingsSchedules?.length > 0) {
      const newLayout = existingsSchedules.map((schedule) => {
        const positionArrayOriginal = positionsArray.filter(
          (position) =>
            position?.positionOriginalId_ ===
              Number(schedule?.originalPositionId) &&
            position.shift === schedule.shift
        );
        // console.log(positionArrayOriginal)
        const existing = positionsArray.findIndex(
          (position) =>
            position?.positionId === schedule?.position?.id &&
            position.shift === schedule.shift
        );

        const indexPosition =
          schedule?.positionName.split(" ")[
            schedule?.positionName.split(" ").length - 1
          ];
        const indexxxx = positionsArray.findIndex((record) => {
          if (record.title) return false;
          return (
            record.positionName[record?.positionName?.length - 1] ===
              indexPosition &&
            record.shift === schedule.shift &&
            record.positionId === Number(schedule?.originalPositionId)
          );
        });

        return {
          i: uuid(),
          originalRecordId: schedule.id,
          minH: 1,
          maxH: 1,
          minW: 1,
          show: existing !== -1,
          pending: false,
          name: toCamelCase(schedule.partner.person.firstName),
          position: schedule.positionName,
          originalId: schedule.partner.id,
          shift: schedule.shift,
          x: Number(schedule.positionX),

          // Id position
          // y: positionsArray.findIndex((position) => (position.positionId === schedule.position.id) && (position.shift === schedule.shift)),

          // Name quintana
          // y: positionsArray.findIndex(
          //   (position) =>
          //     position.positionName === schedule.positionName &&
          //     position.shift === schedule.shift
          // ),

          y: indexxxx,

          w: Number(schedule.positionW),
          h: 1,
          positionId: Number(schedule?.originalPositionId),
          positionOriginalId_: Number(schedule?.originalPositionId),
        };
      });

      setLayout(newLayout);
      formik.setFieldValue("schedule", newLayout);
    } else {
      setLayout([]);
    }
  }, [existingsSchedules]);

  React.useEffect(() => {
    setUpdate(!update);
  }, [turno, selectedDay]);

  const [existingAssistances, setExistingAssistances] = React.useState<any[]>(
    []
  );

  React.useEffect(() => {
    const fetchWorkers = async () => {
      const workers = await readPartnersByBranch(branchId, { active: true });

      setWorkers(
        workers.map((worker) => ({
          ...worker,
          show: true,
          pending: false,
        }))
      );
    };

    const setHours = async () => {
      const hoursArray = await generateTimeIntervals(
        hours?.matutinoStart,
        hours?.vespertinoEnd
      );
      hoursArray.unshift(
        { key: -99, display: "Hora" },
        { key: -99, display: "Hora" }
      );
      // console.log('hoursArray', hoursArray);
      setHoursRange(hoursArray);
    };

    const fetchDayAssistances = async () => {
      const selectedDate = getDateByDayOfWeek(
        weeks[selectedWeek]?.value,
        weekDays[selectedDay].name
      );
      const assistances = await findAssistanceByDate(
        moment(selectedDate).format("YYYY-MM-DD")
      );
      setExistingAssistances(assistances);
    };

    setHours();
    fetchWorkers();
    fetchDayAssistances();
  }, []);

  const sortWorkers = () => {
    const reorderedShifts = [
      shifts[turno],
      ...shifts.filter((_, index) => index !== turno),
    ];

    const resultArray: any = [];

    reorderedShifts.forEach((shift) => {
      const workersByShift = workers.filter(
        (worker) => worker.candidate?.shift?.name === shift.name
      );
      workersByShift.forEach((worker) => {
        resultArray.push(worker);
      });
    });

    setWorkers(resultArray);
  };

  useEffect(() => {
    sortWorkers();
  }, [turno]);

  const handleDrop = (id: string) => {
    const worker = workers.find((worker) => String(worker.id) === String(id));

    if (worker) {
      const position = positionsArray[lastItem?.y]?.positionName;
      const positionOriginalId_ =
        positionsArray[lastItem?.y]?.positionOriginalId_;
      console.log(showSummary);
      if (
        Boolean(
          position === "" || position === undefined || position === null
        ) &&
        !showSummary
      ) {
        notification(
          "Precaución",
          "No se puede asignar a esta posición",
          "warning"
        );
        return;
      }

      const newItem = {
        i: uuid(),
        minH: 1,
        maxH: 1,
        minW: 1,
        show: false,
        pending: true,
        name: toCamelCase(
          `${worker.person.firstName} ${worker.person.lastName} ${worker.person.secondLastName}`
        ),
        position: position,
        positionId: positionsArray[lastItem?.y]?.positionId,
        originalId: worker.id,
        shift: positionsArray[lastItem?.y]?.shift,
        positionOriginalId_,
      };

      setLayout((currentLayout) => [...currentLayout, newItem]);

      setUpdate(!update);
    }
  };

  useEffect(() => {
    formik.setFieldValue("schedule", layout);
    const hasPending = layout.findIndex((item) => item?.pending);

    if (lastItem && hasPending !== -1) {
      updatePending(lastItem, hasPending);
      setLastItem(null);
    }
  }, [lastItem, layout]);

  useEffect(() => {
    setLayout((currentLayout) => {
      const newLayout = currentLayout.map((record) => ({
        ...record,
        i: uuid(),
      }));
      return newLayout;
    });
  }, [update]);

  const updatePending = (oldRecord: any, neRecord: number) => {
    const newLayout = [...layout];

    newLayout.filter((item) => item.pending);

    newLayout[neRecord] = {
      ...newLayout[neRecord],
      x: oldRecord.x,
      y: oldRecord.y,
      w: 16,
      h: 1,
      pending: false,
      show: true,
    };

    if (!isOverlapping(newLayout, newLayout[neRecord])) {
      const clanedLayout = layout.filter((item) => item?.pending === false);
      setLayout(clanedLayout);
      return;
    }

    const itemsFounds = newLayout.filter(
      (item) => item.originalId === layout[neRecord].originalId
    );

    const newLayoutA = [...layout];
    newLayoutA[neRecord] = {
      ...newLayout[neRecord],
      x: oldRecord.x,
      y: oldRecord.y,
      w: itemsFounds.length > 2 ? 2 : 16,
      h: 1,
      pending: false,
      show: true,
    };
    const maxY = Number(positionsArray?.length);
    if (oldRecord.y >= maxY) {
      newLayout.splice(neRecord, 1);
    }

    setLayout(newLayoutA);
  };

  const deleteItem = (id: string) => {
    if (
      existingAssistances.find(
        (assistance) =>
          assistance.partner.id ===
          layout.find((item) => item.i === id)?.originalId
      )
    ) {
      notification(
        "Precaución",
        "El trabajador tiene asistencias registradas",
        "warning"
      );
    }

    setLayout((currentLayout) => {
      const newLayout = currentLayout.filter((item) => item?.i !== id);
      return newLayout;
    });

    setUpdate(!update);
  };

  const handleResize = (layout_, oldItem, newItem, placeholder, e, element) => {
    const index = layout.findIndex((item) => item?.i === oldItem?.i);
    let item = { ...layout[index] }; // Haz una copia en lugar de usar JSON.stringify

    if (item) {
      item.w = newItem.w;
    }

    const newLayout = [...layout];
    newLayout[index] = item;
    setLayout(newLayout);
  };

  const handleResizeStop = (
    layout_,
    oldItem,
    newItem,
    placeholder,
    e,
    element
  ) => {
    const newLayout = [...layout];

    const index = newLayout.findIndex((item) => item?.i === oldItem?.i);
    let item = { ...newLayout[index] };

    item.h = newItem.h;
    item.w = newItem.w;
    item.y = newItem.y;
    item.x = newItem.x;

    const overlapDetected = !isOverlapping(newLayout, item);

    if (!overlapDetected) {
      if (
        existingAssistances.find(
          (assistance) =>
            assistance.partner.id ===
            layout.find((item) => item.i === oldItem.i)?.originalId
        )
      ) {
        notification(
          "Precaución",
          "El trabajador tiene asistencias registradas",
          "warning"
        );
      }

      newLayout[index] = item;
      setLayout(newLayout);
    } else {
      item.h = oldItem.h;
      item.w = oldItem.w;
      item.y = oldItem.y;
      item.x = oldItem.x;

      newLayout[index] = item;

      setLayout(newLayout);
      setUpdate(!update);
    }
  };

  const onDragStop = (layout_, oldItem, newItem, placeholder, e, element) => {
    const currentPosition = positionsArray[newItem.y]?.positionName;
    const positionOriginalId_ = positionsArray[newItem?.y]?.positionOriginalId_;
    if (
      currentPosition === "" ||
      currentPosition === undefined ||
      currentPosition === null
    ) {
      // eliminar el item:
      const newLayout = layout.filter((item) => item.i !== oldItem.i);
      setLayout(newLayout);
      setUpdate(!update);

      notification(
        "Precaución",
        "No se puede asignar a esta posición",
        "warning"
      );
      return;
    }

    const copyLayout = [...layout];
    const updatedLayout = copyLayout.map((item) =>
      item.i === oldItem.i
        ? {
            ...item,
            x: newItem.x,
            y: newItem.y,
            h: newItem.h,
            position: currentPosition,
            shift: positionsArray[newItem.y]?.shift,
            positionOriginalId_: positionOriginalId_,
          }
        : item
    );

    const overlappingItem = isOverlapping(
      updatedLayout,
      updatedLayout.find((item) => item.i === oldItem.i)
    );

    if (overlappingItem) {
      // console.log('No overlapping detected');
      // Verificar si el trabajador tiene asistencias registradas
      if (
        existingAssistances.find(
          (assistance) =>
            assistance.partner.id ===
            layout.find((item) => item.i === oldItem.i)?.originalId
        )
      ) {
        notification(
          "Precaución",
          "El trabajador tiene asistencias registradas",
          "warning"
        );
      }

      setLayout(updatedLayout);
      setUpdate(!update);
    } else {
      // console.log('Overlapping detected, reverting changes');
      setLayout([...layout]); // Revertir al layout original si hay solapamiento
      setUpdate(!update);
    }
  };

  const autocompleteLayout = async () => {
    setLayout([]);
    const hoursRanges = generateTimeSlots(hours);

    const availablePositions =
      turno !== 3
        ? positionsArray.filter(
            (position) => position.shift === shifts[turno].name
          )
        : positionsArray;
    const availableWorkers =
      turno !== 3
        ? workers.filter(
            (worker) => worker.candidate?.shift?.name === shifts[turno].name
          )
        : workers;

    const processedWorkers = new Set();
    let newLayout: any[] = [];

    await Promise.all(
      availablePositions.map(async (position) => {
        const workersByPosition = availableWorkers.filter(
          (worker) =>
            worker.candidate?.vacancy?.position?.name ===
              position.originalPositionName &&
            worker.candidate?.shift?.name === position.shift &&
            !processedWorkers.has(worker.id)
        );

        if (workersByPosition.length === 0) {
          return;
        }

        const worker = workersByPosition[0];

        // Encontrar si no es su dia libre

        const record = freeDays.find((record) => record.id === worker.id);

        if (record && record?.freeDays.includes(selectedDay)) {
          processedWorkers.add(worker.id);
          return;
        }

        processedWorkers.add(worker.id);

        const partnerSchedules = await findPartnerSchedule(
          worker.id,
          `dayOfWeek=${selectedDay}`
        );

        if (partnerSchedules.length > 0) {
          partnerSchedules.forEach((schedule) => {
            const x =
              hoursRange.findIndex(
                (hour) => hour.display === schedule.startTime
              ) - 2;
            const y = positionsArray.findIndex(
              (positionA) =>
                positionA.positionName === position.positionName &&
                positionA.shift === position.shift
            );
            const w =
              hoursRange.findIndex(
                (hour) => hour.display === schedule.endTime
              ) -
              hoursRange.findIndex(
                (hour) => hour.display === schedule.startTime
              ) +
              1;

            newLayout.push({
              i: uuid(),
              minH: 1,
              maxH: 1,
              minW: 1,
              show: true,
              pending: false,
              name: toCamelCase(
                `${worker.person.firstName} ${worker.person.lastName} ${worker.person.secondLastName}`
              ),
              position: position.positionName,
              originalId: worker.id,
              x,
              y,
              w,
              h: 1,
              positionId: position.positionId,
              shift: position.shift,
              positionOriginalId_: Number(position?.positionOriginalId_),
            });
          });
        } else {
          const shiftIndex =
            position.shift === "Matutino"
              ? 0
              : position.shift === "Intermedio"
              ? hoursRanges[0].ranges.findIndex(
                  (range) => range.display === hoursRanges[1].ranges[0]?.display
                )
              : position.shift === "Vespertino"
              ? hoursRanges[0].ranges.findIndex(
                  (range) => range.display === hoursRanges[1].ranges[0]?.display
                ) +
                hoursRanges[1].ranges.findIndex(
                  (range) => range.display === hoursRanges[2].ranges[0]?.display
                )
              : 0;

          const y = positionsArray.findIndex(
            (positionA) =>
              positionA.positionName === position.positionName &&
              positionA.shift === position.shift
          );

          const width =
            position.shift === "Matutino"
              ? hoursRanges[0].ranges.length
              : position.shift === "Intermedio"
              ? hoursRanges[1].ranges.length
              : position.shift === "Vespertino"
              ? hoursRanges[2].ranges.length
              : 0;

          newLayout.push({
            i: uuid(),
            minH: 1,
            maxH: 1,
            minW: 1,
            show: true,
            pending: false,
            name: toCamelCase(
              `${worker.person.firstName} ${worker.person.lastName} ${worker.person.secondLastName}`
            ),
            position: position.positionName,
            originalId: worker.id,
            x: shiftIndex,
            y,
            w: width,
            h: 1,
            positionId: position.positionId,
            shift: worker.candidate?.shift?.name,
            positionOriginalId_: Number(position?.positionOriginalId_),
          });
        }
      })
    );

    // console.log('newLayout', newLayout);
    setLayout(newLayout);
  };

  const [filter, setFilter] = React.useState("");
  const [showSummary, setShowSummary] = React.useState(false);

  const generateSummary = () => {
    setShowSummary(!showSummary);
  };

  // Si cambia la semana hacer null los freeDays
  // Actualizar los freeDays

  React.useEffect(() => {
    if (freeDays.length > 0) {
      dispatch(resetScheduleState());
      dispatch(setFetchFreeDays())
    }
  }, [formik.values.week]);

  if (!hoursRange.length) return;

  return (
    <React.Fragment>
      <div className={`row mb-4 fs-13px col-md-12`}>
        <label className={`form-label col-form-label col-md-3 text-end`} />
        <div className={`col-md-9`} role="group">
          <button
            className="btn btn-secondary ms-2"
            onClick={() => {
              generateSummary();
              setCompactFilters({ ...defaultFilters, resumen: true });
            }}
          >
            {showSummary ? "Vista detallada" : "Vista compacta"}
          </button>
          {showSummary && (
            <React.Fragment>
              <button
                className="btn btn-azul ms-2"
                onClick={() =>
                  setCompactFilters({ ...defaultFilters, resumen: true })
                }
                style={{
                  ...(!compactFilters.resumen && {
                    // opacity: 0.5
                    opacity: 0.5,
                  }),
                }}
              >
                Resumen
              </button>

              <button
                className="btn btn-azul ms-2"
                onClick={() =>
                  setCompactFilters({ ...defaultFilters, realTime: true })
                }
                style={{
                  ...(!compactFilters.realTime && {
                    opacity: 0.5,
                  }),
                }}
              >
                Tiempo real
              </button>

              <button
                className="btn btn-azul ms-2"
                onClick={() =>
                  setCompactFilters({ ...defaultFilters, assistence: true })
                }
                style={{
                  ...(!compactFilters.assistence && {
                    opacity: 0.5,
                  }),
                }}
              >
                Asistencias
              </button>

              <button
                className="btn btn-azul ms-2"
                onClick={() =>
                  setCompactFilters({ ...defaultFilters, late: true })
                }
                style={{
                  ...(!compactFilters.late && {
                    opacity: 0.5,
                  }),
                }}
              >
                Retardo
              </button>

              <button
                className="btn btn-azul ms-2"
                onClick={() =>
                  setCompactFilters({
                    ...defaultFilters,
                    toArrive: true,
                  })
                }
                style={{
                  ...(!compactFilters.toArrive && {
                    opacity: 0.5,
                  }),
                }}
              >
                Proximos a llegar
              </button>

              <button
                className="btn btn-azul ms-2"
                onClick={() =>
                  setCompactFilters({
                    ...defaultFilters,
                    withoutAssistence: true,
                  })
                }
                style={{
                  ...(!compactFilters.withoutAssistence && {
                    opacity: 0.5,
                  }),
                }}
              >
                Sin asistencia
              </button>
            </React.Fragment>
          )}
        </div>
      </div>

      <div
        className="row d-flex justify-content-between"
        style={{
          flex: 1,
          flexDirection: "row",
          borderRadius: "0.25rem",
          overflow: "hidden",
          border: "1px solid #AFAFAF55",
        }}
      >
        {!showSummary && (
          <div
            className="col-2 py-2"
            style={{
              backgroundColor: "#F0F0F0",
              maxHeight: 800,
            }}
          >
            <h4 className="mb-2">Trabajadores</h4>
            <input
              type="text"
              className="form-control mb-2"
              placeholder="Buscar trabajador"
              onChange={(e) => setFilter(e.target.value)}
              style={{
                borderRadius: "0.25rem",
              }}
            />
            <div
              style={{
                overflowY: "auto",
                maxHeight: "700px",
              }}
            >
              {workers
                .filter((worker) => worker.show)
                .filter(
                  (worker) =>
                    worker.person.firstName
                      .toLowerCase()
                      .includes(filter.toLowerCase()) ||
                    worker.person.lastName
                      .toLowerCase()
                      .includes(filter.toLowerCase()) ||
                    worker.person.secondLastName
                      .toLowerCase()
                      .includes(filter.toLowerCase()) ||
                    worker.person.curp
                      .toLowerCase()
                      .includes(filter.toLowerCase()) ||
                    worker.candidate?.vacancy?.position?.name
                      .toLowerCase()
                      .includes(filter.toLowerCase())
                )
                .filter((worker) => !worker.candidate?.vacancy?.isDeleted)
                .sort(
                  (a, b) =>
                    a.candidate?.vacancy?.position?.order -
                    b.candidate?.vacancy?.position?.order
                )
                .map((worker) => (
                  <WorkerRow
                    partner={worker}
                    originalId={worker.id}
                    name={toCamelCase(
                      `${worker.person.firstName} ${worker.person.lastName} ${worker.person.secondLastName}`
                    )}
                    position={worker.candidate?.vacancy?.position?.name}
                    week={selectedWeek}
                    selectedDay={selectedDay}
                    freeDay={
                      worker.candidate.freeDay === weekDays[selectedDay].name
                    }
                    shift={worker.candidate?.shift?.name}
                    key={worker.id}
                    handleDrop={(id: string) => handleDrop(id)}
                    curp={worker.person.curp}
                    count={
                      layout.filter(
                        (item) =>
                          item?.originalId === worker.id &&
                          item?.show &&
                          !item?.pending
                      ).length
                    }
                  />
                ))}
            </div>
          </div>
        )}

        <div
          className={`col-${!showSummary ? "10" : "12"}`}
          style={{
            paddingBlock: "10px",
            boxShadow: "4px 6px 6px rgba(0, 0, 0, 0.1)",
            overflowY: "auto",
            overflowX: "auto",
            position: "relative",
            maxHeight: 800,
          }}
        >
          <>
            <div
              style={{
                position: "sticky",
                top: -10,
                zIndex: 5,
                backgroundColor: "white",
              }}
            >
              <GridLayout
                className="layout"
                layout={Array.from({ length: hoursRange.length }, (_, i) => ({
                  i: i.toString(),
                  x: i,
                  y: 0,
                  w: 1,
                  h: 1,
                  static: true,
                }))}
                cols={hoursRange.length}
                rowHeight={40}
                width={2400}
                margin={[0, 0]}
                compactType={null}
              >
                {hoursRange.map((hour, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      textAlign: "center",
                      borderRight: index !== 0 ? "2px solid #EEE" : "none",
                      borderBottom: "2px solid #EEE",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: 600,
                      fontSize: "10px",
                      backgroundColor: "white",
                    }}
                  >
                    {index === 0 && turno !== 3 && (
                      <React.Fragment>
                        <a
                          href="#/"
                          onClick={() => handleSortPositions("name")}
                          className="fw-600 me-3"
                        >
                          Nombre
                          <i
                            className={`fa-solid ${
                              sortBy === "name" &&
                              (sortType === "Asc"
                                ? "fa-sort-up"
                                : "fa-sort-down")
                            } ms-1`}
                          ></i>
                        </a>
                      </React.Fragment>
                    )}
                    {index > 1 && hour.display}
                  </div>
                ))}
              </GridLayout>
            </div>
            {showSummary ? (
              <ScheduleCompact
                hours={hours}
                existingsSchedules={existingsSchedules}
                formik={formik}
                positionsArray={positionsArray}
                setPositionsArray={setPositionsArray}
                selectedWeek={selectedWeek}
                selectedDay={selectedDay}
                turno={turno}
                hoursRange={hoursRange}
                deleteItem={deleteItem}
                handleResize={handleResize}
                handleResizeStop={handleResizeStop}
                layout={layout}
                onDragStop={onDragStop}
                setLastItem={setLastItem}
                compactFilters={compactFilters}
                updateLine={() => {}}
                // aquiaquiaquiaqui
              />
            ) : (
              <div className="d-flex flex-row" style={{ width: 2400 }}>
                <div
                  style={{
                    position: "sticky",
                    left: -10,
                    zIndex: 6,
                    backgroundColor: "white",
                  }}
                >
                  {/* {showSummary ? mergePositions(positionsArray) : positionsArray */}

                  {positionsArray
                    .filter((position) => !position.isDeleted)
                    .map((key, index) => {
                      return (
                        <div
                          key={index}
                          data-grid={{
                            x: 0,
                            y: index,
                            w: key?.title ? 36 : 2,
                            h: 1,
                            static: true,
                          }}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "left",
                            borderRight: "2px solid #EEE",
                            borderBottom: `${
                              key?.title ? "3px" : "2px"
                            } solid #EEE`,
                            borderTop: `${key?.title ? "3px solid" : ""} ${
                              key?.title === "Matutino"
                                ? "#FFD700"
                                : key.title === "Vespertino"
                                ? "#FF6347"
                                : "#4682B4"
                            }`,
                            paddingInline: "5px",
                            fontWeight: 600,
                            height: 50,
                            borderLeft: `3px solid ${
                              !key?.shifts
                                ? key.shift === "Matutino"
                                  ? "#FFD700"
                                  : key.shift === "Vespertino"
                                  ? "#FF6347"
                                  : "#4682B4"
                                : "#4682B4"
                            }`,
                            backgroundColor: "transparent",
                            width: (2400 / hoursRange?.length) * 2,
                          }}
                        >
                          {key.title && (
                            <p className="w-100 mt-3 fs-15px fw-800 bg">
                              {key?.title ?? key.positionName}
                            </p>
                          )}
                          <Tooltip title={key?.shifts?.join(", ") ?? ""}>
                            <p className="w-100 mt-auto">{key?.positionName}</p>
                          </Tooltip>
                        </div>
                      );
                    })}
                </div>

                <GridLayout
                  className="layout"
                  layout={layout}
                  cols={hoursRange.length}
                  maxRows={positionsArray?.length}
                  rowHeight={50}
                  width={2400}
                  preventCollision={false}
                  allowOverlap={true}
                  isResizable={true}
                  isDroppable={true}
                  onResize={handleResize}
                  onResizeStop={handleResizeStop}
                  onDragStop={onDragStop}
                  compactType={null}
                  margin={[0, 0]}
                  style={{
                    background: generateGradientBackground(
                      hoursRange,
                      hours,
                      turno
                    ),
                    width: `${2400}px`,
                    position: "relative",
                  }}
                  onDrop={(layoutx, layoutItem, _event) => {
                    setLastItem(layoutItem);
                  }}
                >
                  {positionsArray
                    .filter((position) => !position.isDeleted)
                    .map((key, index) => {
                      return (
                        <div
                          key={index}
                          data-grid={{
                            x: 0,
                            y: index,
                            w: key?.title ? 36 : 2,
                            h: 1,
                            static: true,
                          }}
                          style={{
                            fontSize: "10px",
                            display: "none",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "left",
                            borderRight: "2px solid #EEE",
                            borderBottom: `${
                              key?.title ? "3px" : "2px"
                            } solid #EEE`,
                            borderTop: `${key?.title ? "3px solid" : ""} ${
                              key?.title === "Matutino"
                                ? "#FFD700"
                                : key.title === "Vespertino"
                                ? "#FF6347"
                                : "#4682B4"
                            }`,
                            paddingInline: "5px",
                            fontWeight: 600,
                            borderLeft: `3px solid ${
                              key.shift === "Matutino"
                                ? "#FFD700"
                                : key.shift === "Vespertino"
                                ? "#FF6347"
                                : "#4682B4"
                            }`,
                            backgroundColor: "white",
                            position: "sticky",
                            left: 0,
                            zIndex: 3,
                          }}
                        >
                          {key.title && (
                            <p className="w-100 mt-3 fs-15px fw-800 bg">
                              {key?.title ?? key.positionName}
                            </p>
                          )}
                          <p className="w-100 mt-auto">{key.positionName}</p>
                        </div>
                      );
                    })}

                  {layout.map((item, index) => {
                    if (item?.x === undefined || !item?.show) return <></>;
                    return (
                      <div
                        key={item?.i}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "move",
                        }}
                        data-grid={{ x: item?.x, y: item?.y, w: item?.w, h: 1 }}
                      >
                        <div
                          style={{
                            paddingLeft: "8px",
                            width: "100%",
                            borderRadius: "5px",
                            paddingBlock: "4px",
                            ...getBackgroundColor(item, index, positionsArray),
                          }}
                          className="shadow-sm"
                        >
                          <Tooltip
                            title={`${item.name} | ${item.position} | ${
                              item.w / 2
                            } hrs`}
                            arrow
                          >
                            <div className="d-flex flex-row">
                              <div
                                onMouseDown={(e) => e.stopPropagation()}
                                className="position-absolute m-2"
                                style={{
                                  left: 0,
                                  top: "35%",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                                onClick={() => deleteItem(item?.i)}
                              >
                                <i className="fa-solid fa-xmark"></i>
                              </div>
                              <Typography fontWeight={500} marginLeft={1.5}>
                                {item?.name?.split(" ")[0]}
                              </Typography>
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })}
                </GridLayout>
              </div>
            )}
          </>
        </div>

        <div className="w-100 pt-4 pb-2 d-flex justify-content-end">
          <button
            className="btn btn-primary ms-auto"
            onClick={autocompleteLayout}
          >
            Autocompletar
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SheduleComponent;
