import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { findPartnersWithMultipleFunctions } from "../../../services/branchSchedule.service.ts";
import { useAppSettings } from "../../../hooks/useAppSettings.tsx";
import Layout from "../../Layout.tsx";
import { fixedHeadStyle, fixedStyle, weeks } from "../../../utils/constanst.js";
import Title from "../../Title.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/index.ts";
import CustomSelectField from "../../formik/CustomSelectField.tsx";
import { useFormik } from "formik";
import S3Service from "../../../services/s3.service.ts";
import EmptyImage from "../../../assets/img/ic_content.svg";

const DobleFunctionReport = () => {
  let id;

  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  if (user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }

  useAppSettings();

  const [partners2Functions, setPartners2Functions] = useState<any[]>([]);
  const [profileUrls, setProfileUrls] = useState<{ [key: number]: string }>({});

  const formik = useFormik({
    initialValues: {
      week: 1,
    },
    onSubmit: (values) => {
      fetchDependencies();
    },
  });

  const fetchDependencies = async () => {
    if (formik.values.week !== null && formik.values.week !== undefined) {
      const weekValue = weeks[formik.values.week]?.value;
      if (weekValue) {
        const partners = await findPartnersWithMultipleFunctions(id, weekValue);
        setPartners2Functions(partners);

        const profileUrlsTemp: { [key: number]: string } = {};
        for (const partnerData of partners) {
          const profileUrl = await S3Service.getFile(
            "PERFIL_" + partnerData.partner.person.curp
          );
          profileUrlsTemp[partnerData.partner.id] = profileUrl.url;
        }
        setProfileUrls(profileUrlsTemp);
      }
    }
  };

  useEffect(() => {
    fetchDependencies();
  }, [id, formik.values.week]);

  const daysOfWeek = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo",
  ];

  return (
    <Layout>
      <Title
        baseTitle="Resumen Colaboradores"
        basePath=""
        title="Colaboradores con doble función"
      />

      <div className="tab-pane fade active show" id="Puestos">
        <div className="card border-0 m-4 p-4">
          <h5 className="text-center mb-2">Buscar por día</h5>
          <div className="d-flex flex-row w-100 justify-content-center">
            <CustomSelectField
              formik={formik}
              field="week"
              label="Semana"
              list={weeks}
              sm={7}
            />
          </div>
        </div>
      </div>

      {partners2Functions.length === 0 ? (
        <div className="tab-pane fade active show" id="Puestos">
          <div className="card border-0 m-4 p-4">
            <div className="py-4">
              <div className="d-flex">
                <img src={EmptyImage} alt="" className="mx-auto w-250px" />
              </div>
              <h5 className="text-secondary text-center fs-20px">
                Aún no existen datos, selecciona una semana para visualizar la
                información
              </h5>
            </div>
          </div>
        </div>
      ) : (
        <div className="tab-pane fade active show" id="Puestos">
          <div className="card border-0 m-4">
            <div className="tab-content p-3">
              <div>
                <h5>Simbología:</h5>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <span
                      className="badge text-black"
                      style={{
                        backgroundColor: "#22d3ee",
                        border: "1px solid #0002",
                      }}
                    >
                      Matutino
                    </span>
                  </li>
                  <li className="list-inline-item">
                    <span
                      className="badge text-black"
                      style={{
                        backgroundColor: "#bef264",
                        border: "1px solid #0002",
                      }}
                    >
                      Intermedio
                    </span>
                  </li>
                  <li className="list-inline-item">
                    <span
                      className="badge text-black"
                      style={{
                        backgroundColor: "#fcd34d",
                        border: "1px solid #0002",
                      }}
                    >
                      Vespertino
                    </span>
                  </li>
                  <li className="list-inline-item">
                    <span
                      className="badge text-black"
                      style={{
                        backgroundColor: "#75869444",
                        border: "1px solid #0002",
                      }}
                    >
                      Sin doble función
                    </span>
                  </li>
                </ul>
              </div>
              <div className="table-responsive mb-3" style={{ overflowY: "auto", maxHeight: '500px' }}>
                <table className="table table-panel text-nowrap align-middle mb-0">
                  <thead className="sticky-thead table-light text-center">
                    <tr style={{ ...fixedHeadStyle } as React.CSSProperties}>
                      <th rowSpan={2} className="align-middle">
                        Id
                      </th>
                      <th style={{ ...fixedStyle}as React.CSSProperties} rowSpan={2} className="align-middle ">
                        Colaborador
                      </th>
                      {daysOfWeek.map((day) => (
                        <th key={day} colSpan={2} className="align-middle">
                          {day}
                        </th>
                      ))}
                    </tr>
                    <tr>
                      {daysOfWeek.map((day) => (
                        <React.Fragment key={day}>
                          <th>Puesto(s)</th>
                          <th>Hora laboral</th>
                        </React.Fragment>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {partners2Functions.map((partnerData) => (
                      <tr key={partnerData.partner.id}>
                        <td>{partnerData.partner.id}</td>
                        <td style={{ ...fixedStyle} as React.CSSProperties} >
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <img
                              src={
                                profileUrls[partnerData.partner.id] ||
                                "https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg?20200418092106"
                              }
                              className="rounded-circle"
                              style={{ width: 50, height: 50 }}
                            />
                            <span className="mt-2">
                              {partnerData.partner.person.firstName}{" "}
                              {partnerData.partner.person.lastName}{" "}
                            </span>
                            <span className="text-secondary"></span>
                          </div>
                        </td>
                        {daysOfWeek.map((day) => {
                          const dayData = partnerData.days.find(
                            (d) => d.dayOfWeek === day
                          );
                          const hasData = !!dayData;
                          return (
                            <React.Fragment key={day}>
                              <td
                                style={{
                                  backgroundColor: hasData
                                    ? "transparent"
                                    : "#f0f0f0",
                                }}
                              >
                                {dayData?.positions.map((position) => {
                                  let shiftColor = "transparent";
                                  if (position.shift === "Matutino") {
                                    shiftColor = "#22d3ee";
                                  } else if (position.shift === "Intermedio") {
                                    shiftColor = "#bef264";
                                  } else if (position.shift === "Vespertino") {
                                    shiftColor = "#fcd34d";
                                  }

                                  return (
                                    <div
                                      key={position.id}
                                      className="d-flex align-items-center"
                                      style={{ marginBottom: "4px" }}
                                    >
                                      <span
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                          backgroundColor: shiftColor,
                                          borderRadius: "50%",
                                          display: "inline-block",
                                          marginRight: "8px",
                                        }}
                                      ></span>
                                      {position.name}
                                    </div>
                                  );
                                })}
                              </td>
                              <td
                                style={{
                                  backgroundColor: hasData
                                    ? "transparent"
                                    : "#f0f0f0",
                                }}
                              >
                                {dayData?.positions.map((position) => {
                                  const startTime =
                                    position.startTime.split(" - ")[0];
                                  const endTime =
                                    position.endTime.split(" - ")[1];
                                  return (
                                    <div
                                      key={position.id}
                                      style={{ marginBottom: "4px" }}
                                    >
                                      {startTime} - {endTime}
                                    </div>
                                  );
                                })}
                              </td>
                            </React.Fragment>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default DobleFunctionReport;
