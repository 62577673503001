import React from "react";
import { useDispatch, useSelector } from "react-redux";
import S3Service from "../../../../services/s3.service.ts";
import { notification } from "../../../../utils/Notifications.tsx";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import ModalContainer from "../../../ModalContainer.tsx";
import ModalFreeDays from "./ModalFreeDays.tsx";
import { fetchFreeDaysByDateRangeAndPartnerId } from "../../../../services/freeDay.service.ts";
import { weekDays, weeks } from "../../../../utils/constanst.js";
import { RootState } from "../../../../store/index.ts";
import { addFreeDay } from "../../../../store/slices/scheduleSlice.ts";

const WorkerRow = ({
  originalId,
  name,
  position,
  shift,
  handleDrop,
  curp,
  freeDay,
  count,
  partner,
  week,
  selectedDay,
}) => {
  const { freeDays, fetchFreeDays } = useSelector((state: RootState) => state.schedule);
  const dispatch = useDispatch();
  const [profileImage, setProfileImage] = React.useState(
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
  );
  const [isFreeDay, setIsFreeDay] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const fetchProfileImage = async () => {
    try {
      const profileUrl = await S3Service.getFile("PERFIL_" + curp);
      setProfileImage(profileUrl.url);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchFreeDay = async () => {
    try {
      const freeDays = await fetchFreeDaysByDateRangeAndPartnerId(
        weeks[week]?.value,
        partner.id
      );
      if (freeDays?.days && Array.isArray(freeDays.days)) {
        const selectedDays = freeDays.days.map((day) => {
          const index = weekDays.findIndex((d) => d.name === day);

          if (index === -1) {
            throw new Error(`Día no válido: ${day}`);
          }

          return index;
        });

        const record = {
          id: partner.id,
          freeDays: selectedDays,
        };
        dispatch(addFreeDay(record));
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchProfileImage();
    fetchFreeDay();
  }, [week, fetchFreeDays]);

  React.useEffect(() => {
    if (freeDays.length > 0) {
      const freeDay = freeDays.find((f) => f.id === partner.id);
      if (freeDay) {
        setIsFreeDay(freeDay.freeDays.includes(selectedDay));
      }
    } 
  }, [week, selectedDay, freeDays]);

  return (
    <React.Fragment>
      <div
        className="card droppable-element"
        unselectable="on"
        draggable={true}
        onContextMenu={handleContextMenu}
        style={{
          backgroundColor: (() => {
            if (isFreeDay) return "#fee0d2";
            if (count === 1) return "#e5f5e0";
            if (count > 1) return "#deebf7";
            return "white";
          })(),
          marginBottom: "10px",
          cursor: "grab",
        }}
        onDragEnd={(e) => {
          if (isFreeDay) {
            e.preventDefault();
            notification("Aviso", "Es el día libre del colaborador", "warning");
          }
          if (e.dataTransfer.dropEffect === "copy") {
            handleDrop(String(originalId));
          }
        }}
      >
        <Card
          sx={{
            display: "flex",
            backgroundColor: "rgba(255, 255, 255, 0)",
            flexDirection: { xs: "column", sm: "row" },
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            gap: 1,
            p: 1,
          }}
        >
          <Grid
            direction={"column"}
            display={"flex"}
            item
            sm={3}
            container
            alignItems="center"
            justifyItems={"center"}
          >
            <CardMedia
              component="img"
              sx={{
                width: 40,
                height: 40,
                mx: "auto",
                my: "auto",
                py: 0,
                borderRadius: "50%",
                userSelect: "none",
              }}
              image={profileImage}
              alt="Colaborador"
              onError={() =>
                setProfileImage(
                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                )
              }
            />
            <Typography
              sx={{
                fontSize: "0.6rem",
                textAlign: "center",
                color: "#6e6e6e",
                userSelect: "none",
              }}
            >
              {count} turnos
            </Typography>
          </Grid>

          <Grid item sm={7}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: 120,
                justifyContent: "left",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  width: "100%",
                }}
                style={{ padding: 2 }}
              >
                <h4
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    userSelect: "none",
                    width: "100%",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  {name} {isFreeDay && `(Día libre)`}
                </h4>
                <h4
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: "0.7rem",
                    marginBlock: "0.2rem",
                    userSelect: "none",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  {position}
                </h4>
                <h4
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: "0.7rem",
                    color: "#6e6e6e",
                    userSelect: "none",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  Horario {shift}
                </h4>
              </CardContent>
            </Box>
          </Grid>

          <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            <MenuItem
              onClick={() => {
                setOpen(true);
                handleClose();
              }}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <i
                className="fa-regular fa-calendar"
                style={{ marginRight: "8px" }}
              ></i>
              Días libres
            </MenuItem>
            <MenuItem
              onClick={() => {
                const url = `${window.location.origin}/colaboradores/detalles/${partner.id}`;
                const newWindow = window.open(
                  url,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <i
                className="fa-regular fa-user"
                style={{ marginRight: "8px" }}
              ></i>
              Perfil
            </MenuItem>
          </Menu>
        </Card>
      </div>
      <ModalContainer
        open={open}
        title="Configurar días libres"
        closeFn={() => setOpen(false)}
        children={
          <ModalFreeDays
            partnerId={partner.id}
            setShowModal={(e) => setOpen(e)}
            weekSelected={week}
          />
        }
      />
    </React.Fragment>
  );
};

export default WorkerRow;
