import httpClient from "./httpClient";

const prefix = "/branch-schedule";

/* templates */
export async function readTemplate(id: number) {
  return (await httpClient.get(`${prefix}/templates/${id}`)).data;
}

export async function readTemplates() {
  return (await httpClient.get(`${prefix}/templates`)).data;
}

export async function paginateTemplates(query: string) {
  return (await httpClient.get(`${prefix}/templates/paginate?${query}`)).data;
}

export async function saveTemplates(name: string) {
  return (await httpClient.post(`${prefix}/templates`, { name })).data;
}

export async function updateTemplates(id: number, name: string) {
  return (await httpClient.patch(`${prefix}/templates/${id}`, { name })).data;
}

export async function deleteTemplates(id: number) {
  return (await httpClient.delete(`${prefix}/templates/${id}`)).data;
}

/* positions */
export async function readPositions() {
  return (await httpClient.get(`${prefix}/positions`)).data;
}

export async function readPositionsByBranch(id: number) {
  return (await httpClient.get(`${prefix}/positions/branch/${id}`)).data;
}

export async function readPositionsByBranchAndShift(id: number, shift: string) {
  return (await httpClient.get(`${prefix}/positions/branch/${id}/${shift}`))
    .data;
}

export async function savePositions(data: any) {
  return (await httpClient.post(`${prefix}/template-positions`, data)).data;
}

export async function updatePositions(id: number, data: any) {
  return (await httpClient.patch(`${prefix}/positions/${id}`, data)).data;
}

export async function deletePositions(id: number) {
  return (await httpClient.delete(`${prefix}/positions/${id}`)).data;
}

/* schedules */
export async function updateAssistance(id: number, data: any) {
  return (await httpClient.patch(`${prefix}/schedules/${id}`, data)).data;
}

export async function updateReplacement(id: number, data: any) {
  return (await httpClient.patch(`${prefix}/schedules/replacement/${id}`, data))
    .data;
}

export async function readSchedules() {
  return (await httpClient.get(`${prefix}/schedules`)).data;
}

export async function readSchedulesByBranchAndWeek(id: number, week: string) {
  return (await httpClient.get(`${prefix}/schedules/branch/${id}/week/${week}`))
    .data;
}

export async function readSchedulesByBranchShiftWeekDay(
  id: number,
  shift: string,
  week: string,
  dayOfWeek: string
) {
  return (
    await httpClient.get(
      `${prefix}/schedules/specific/${id}/${shift}/${week}/${dayOfWeek}`
    )
  ).data;
}

export async function findByExactDateAndPartner(
  exactDate: string,
  partnerId: number
) {
  return (
    await httpClient.get(
      `${prefix}/schedules/assistance/${exactDate}/${partnerId}`
    )
  ).data;
}

export async function saveSchedule(data: any) {
  return (await httpClient.post(`${prefix}/schedules`, data)).data;
}

export async function updateSchedules(id: number) {
  return (await httpClient.patch(`${prefix}/schedules/${id}`)).data;
}

export async function deleteSchedules(id: number) {
  return (await httpClient.delete(`${prefix}/schedules/${id}`)).data;
}

export async function findByExactDateAndBranch(
  exactDate: string,
  branchId: number
) {
  return (
    await httpClient.get(
      `${prefix}/schedules/exact-date/${exactDate}/branch/${branchId}`
    )
  ).data;
}

export async function findNotAssistance(query: string) {
  return (await httpClient.get(`${prefix}/schedules/not-assistance?${query}`))
    .data;
}

export async function findAllSchedulesByBranch(id: number, query: string) {
  return (await httpClient.get(`${prefix}/schedules/branch/${id}?${query}`))
    .data;
}

/* Hours */
export async function createHours(data: any) {
  return (await httpClient.post(`${prefix}/hours`, data)).data;
}

export async function readHoursByBranch(id: number) {
  return (await httpClient.get(`${prefix}/hours/branch/${id}`)).data;
}

export async function findPartnerSchedule(id: number, query: string) {
  return (await httpClient.get(`${prefix}/partnerSchedule/${id}?${query}`))
    .data;
}

/* History */
export async function readHistoryByBranch(id: number) {
  return (await httpClient.get(`${prefix}/history/branch/${id}`)).data;
}

export async function createHistory(data: any) {
  return (await httpClient.post(`${prefix}/history`, data)).data;
}

/* Incidents */
export async function readIncidentsByBranch(
  branch: number,
  date: string,
  type: string
) {
  return (
    await httpClient.get(
      `${prefix}/incidents-schedule?branch=${branch}&date=${date}&type=${type}`
    )
  ).data;
}

export async function countIncidentsByBranch(branch: number, date: string) {
  return (
    await httpClient.get(
      // `${prefix }/count-incidents-schedule?branch=${branch}&date=${date}`
      `/assistance-check/count-incidents-schedule?branch=${branch}&date=${date}`
    )
  ).data;
}

export async function findPartnersByBranchAndCompleteWeek(
  branchId: number,
  exactDate: string
) {
  return (
    await httpClient.get(
      `${prefix}/partner-schedule/${branchId}/${exactDate}`
    )
  ).data;
}

export async function findPartnersWithMultipleFunctions(
  branchId: number,
  exactDate: string
) {
  return (
    await httpClient.get(
      `${prefix}/partner-multiple-functions/${branchId}/${exactDate}`
    )
  ).data;
}

export async function findPartnersWithMultipleShifts(
  branchId: number,
  exactDate: string
) {
  return (
    await httpClient.get(
      `${prefix}/partner-multiple-shifts/${branchId}/${exactDate}`
    )
  ).data;
}

export async function findPartnersAllWeek(
  branchId: number,
  exactDate: string
) {
  return (
    await httpClient.get(
      `${prefix}/partner-all-week/${branchId}/${exactDate}`
    )
  ).data;
}
export async function findPartnersWithSingleWorkDay(
  branchId: number,
  exactDate: string
) {
  return (
    await httpClient.get(
      `${prefix}/partner-single-work-day/${branchId}/${exactDate}`
    )
  ).data;
}

export async function saveIncident(id: number, data: {}) {
  return (await httpClient.patch(`${prefix}/incidents-schedule/${id}`, data))
    .data;
}
